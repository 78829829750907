// modules
import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAtom } from 'jotai';
import languageAtom from '../../utils/GlobalState';

// css
import '../../assets/css/header.css';

// URL
import config from '../../config/config';

// image
const favicon = require('../../assets/images/favicon.png');

function HeaderMo() {
  // About Menu
  const [popup, setPopup] = useState(false);

  // jotai를 통해 language의 전역 상태값을 저장하고 관리합니다
  const [language, setLanguage] = useAtom(languageAtom);

  const googleApply =
    'https://docs.google.com/forms/d/e/1FAIpQLSeDFucUs16bc_NNBvzavSVeqmKN6w1FBsIiVf9OB10KoptcrA/formResponse';

  const googleQA =
    'https://docs.google.com/forms/d/1yWvQVOCJYYd_RwmtrEE5UmrUKchCwubn0t57Uvd3UYo/viewform?edit_requested=true#responses';

  // Link를 통해 다른 페이지로 라우팅 될 때 스크롤을 맨 위로 올려줍니다.
  function componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  // whitepaer download
  const whitepaperClick = (event) => {
    // 팝업 메시지를 띄우기
    alert(
      '본 서비스에서 제공하는 자료는 당사의 공식적인 조사 분석 자료가 아니며, 토큰증권 인프라에 대한 이해를 돕기 위해 작성 되었습니다.',
    );

    // 새로운 창이나 탭에서 다운로드 URL 열기
    window.open(`${config.apiURL}/whitepaper`, '_blank');
  };

  return (
    <>
      <div className="navbar w-[70%] mt-[2rem] mx-auto rounded-xl bg-lightBlackColor font-workSans">
        <div className="navbar-start"></div>
        <div className="navbar-center">
          <Link to="/" onClick={componentDidUpdate}>
            <a className="btn btn-ghost text-sm">
              <img alt="favicon" src={favicon} className="w-[1rem]"></img>
              Shinhan
            </a>
          </Link>
        </div>
        <div className="navbar-end">
          <div className="dropdown">
            <div
              tabIndex={0}
              role="button"
              className="btn btn-ghost btn-circle"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h7"
                />
              </svg>
            </div>
            <ul
              tabIndex={0}
              className="menu menu-xs dropdown-content mt-3 z-[1] p-2 shadow bg-lightBlackColor rounded-xl w-auto"
            >
              <li>
                <Link to="/services" onClick={componentDidUpdate}>
                  <div>Services</div>
                </Link>
              </li>
              <li>
                <Link to="/aboutUs" onClick={componentDidUpdate}>
                  <div>About</div>
                </Link>
              </li>
              <li>
                <div onClick={whitepaperClick}>Whitepaper</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeaderMo;
